// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_cognito_identity_pool_id": "ap-southeast-2:734e827a-8dbd-4b5f-8887-e5ee2087af25",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_g7rVNoDfC",
    "aws_user_pools_web_client_id": "12l2rdrq95fst5vk564p57dpjo",
    "oauth": {
        "domain": "myblog-api-prod.auth.ap-southeast-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://blog.theyucheng.com/",
        "redirectSignOut": "https://blog.theyucheng.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_appsync_graphqlEndpoint": "https://fhiau3crnrenvgchw2lp6vf2uy.appsync-api.ap-southeast-2.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-3fbvazserngjlm3csul3mrmchm"
};


export default awsmobile;
